import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import GoogleAnalytics from './components/GoogleAnalytics/GoogleAnalytics';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <GoogleAnalytics gaCode={process.env.REACT_APP_GA_CODE}>
        <App
          funnelStates={[
            'state_landing',
            'state_questions',
            'state_preEnding',
            'state_endingOk',
            'state_endingKo',
          ]}
        />
      </GoogleAnalytics>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
