import styled from 'styled-components';

import Questions from './Questions/Questions';
import { useState, useEffect } from 'react';
import { Landing } from '../Landing/Landing';
import { Ending } from '../Ending/Ending';
import { PreEnding } from '../PreEnding/PreEnding';

const StyledContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Survey = ({ data, questions, endpoint, sendEvent }) => {
  const [state, setState] = useState();
  const [answers, setAnswers] = useState();
  const [initial, setInitial] = useState(0);

  useEffect(() => {
    data && setState('landing');
  }, [data]);

  const handleSend = async () => {
    const survey = {
      _id: data._id,
      answers: questions.map((question, idx) => {
        return { idQuestion: question.idQuestion, answer: answers[idx] };
      }),
    };
    const resp = await fetch(endpoint, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(survey),
    });

    if (resp.status === 200) {
      const data = await resp.json();
      data.success && setState('endingOk');
    } else {
      setState('endingKo');
    }
  };
  const preSend = (ans) => {
    setInitial(ans.length - 1);
    setAnswers(ans);
    setState('preEnding');
  };

  const renderContent = (state) => {
    switch (state) {
      case 'landing':
        return (
          <Landing
            sendEvent={sendEvent}
            content={data.landingContent}
            onStart={() => setState('questions')}
          />
        );
      case 'questions':
        return (
          <Questions
            savedAnswers={answers}
            onAnswers={() => ''}
            sendEvent={sendEvent}
            questions={questions}
            onEnd={preSend}
            initial={initial}
          />
        );
      case 'preEnding':
        return (
          <PreEnding
            state={state}
            sendEvent={sendEvent}
            content={data.preEnding}
            onEnd={handleSend}
            onBack={() => setState('questions')}
          />
        );
      case 'endingOk':
        return (
          <Ending
            state={state}
            sendEvent={sendEvent}
            content={data.endingOkContent}
          />
        );
      case 'endingKo':
        return (
          <Ending
            state={state}
            sendEvent={sendEvent}
            content={data.endingKoContent}
            text={data.repeatButton}
            onEnd={() => handleSend(answers)}
          />
        );
      default:
        return null;
    }
  };
  return <StyledContainer>{renderContent(state)}</StyledContainer>;
};

export default Survey;
