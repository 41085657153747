import { useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';

const createQueue = () => {
  const _queue = [];

  const checkQueue = () => {
    const ev = _queue.shift();
    ev && window.gtag('event', ev.event, ev.parameters);
    _queue.length && checkQueue();
  };
  const start = () => {
    checkQueue();
  };
  const push = (item) => {
    _queue.push(item);
    window.gtag && checkQueue();
  };
  return { push, start };
};
const eventQueue = createQueue();

const GoogleAnalytics = ({ children, gaCode, options = {}, requestCookie }) => {
  const [cookies, setCookie] = useCookies(['cookie-name']);

  const initialize = useCallback(() => {
    const scriptAsync = document.createElement('script');
    scriptAsync.setAttribute('id', 'gtagAsync');
    scriptAsync.setAttribute('async', '');
    scriptAsync.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${gaCode}`
    );
    scriptAsync.onload = () => {
      const script = document.createElement('script');
      script.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
            dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "${gaCode}");`;
      document.head.appendChild(script);
      eventQueue.start();
    };
    document.head.appendChild(scriptAsync);
  }, [gaCode]);

  const requestCookieCallback = useCallback(
    (value) => {
      if (value) {
        setCookie(options.gdprCookie, new Date(), {
          maxAge: 10 * 365 * 24 * 60 * 60,
        });
        initialize();
      }
    },
    [initialize, options.gdprCookie, setCookie]
  );

  useEffect(() => {
    if (options.gdprCookie) {
      if (cookies[options.gdprCookie]) {
        initialize();
      } else {
        requestCookie && requestCookie(requestCookieCallback);
      }
    } else {
      initialize();
    }
  }, [
    options.gdprCookie,
    cookies,
    initialize,
    requestCookie,
    requestCookieCallback,
  ]);

  return children;
};

export const useGtagEvent = () => {
  return (event, parameters) => {
    eventQueue.push({ event, parameters });
  };
};

export default GoogleAnalytics;
