import { useEffect } from 'react';
import styled from 'styled-components';

const StyledLanding = styled.article`
  display: flex;
  flex-direction: column;
  flex: 1;

  img {
    max-width: 100%;
  }
`;
const StyledContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
`;
const StyledButton = styled.button(({ theme }) => {
  return {
    ...theme.mainButton,
    backgroundColor: theme.mainColor,
  };
});

export const Landing = ({ content, onStart, sendEvent }) => {
  useEffect(() => {
    sendEvent('state_landing');
  }, [sendEvent]);
  return (
    <StyledLanding>
      <StyledContent
        dangerouslySetInnerHTML={{ __html: content }}
      ></StyledContent>
      <StyledButton
        onClick={() => {
          sendEvent('go_questions');
          onStart();
        }}
      >
        Comenzar
      </StyledButton>
    </StyledLanding>
  );
};
