import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useLocation } from 'react-router-dom';
import Survey from './components/survey/Survey';
import withFunnelController from './components/withFunnelController/withFunnelController';
import useViewMode from './hooks/useViewMode';
import GlobalStyle from './components/ui/GlobalStyle';
import Head from './components/Head/Head';
import BadSurvey from './components/BadSurvey/BadSurvey';
import Loading from './components/Loading/Loading';

function App({ sendEvent }) {
  const location = useLocation();
  const mode = useViewMode();
  const [data, setData] = useState();
  const [state, setState] = useState('loading');
  const apiEndpoint = `${process.env.REACT_APP_ENDPOINT}/survey/`;
  const idQueryString = new URLSearchParams(location.search).get('id');
  const idPathname = location.pathname.slice(1);
  const id = idQueryString || idPathname;
  const uri = `${apiEndpoint}start/${id}`;
  const sendUri = `${apiEndpoint}send/${id}`;

  useEffect(() => {
    const getData = async () => {
      const fetched = await fetch(uri, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const { data } = await fetched.json();
      if (data && data.length) {
        setData({ ...data[0], theme: { ...data[0].theme, mode } });
      } else {
        setState('ko');
      }
    };
    getData();
  }, [sendEvent, uri, mode]);

  useEffect(() => {
    data && setState('ok');
  }, [data]);
  switch (state) {
    case 'loading':
      return <Loading />;
    case 'ok':
      return (
        <ThemeProvider theme={data.theme}>
          <GlobalStyle />
          <Head data={data} />
          <Survey
            sendEvent={sendEvent}
            data={data}
            questions={data.questions}
            endpoint={sendUri}
          />
        </ThemeProvider>
      );
    default:
      return <BadSurvey />;
  }
}

export default withFunnelController(App);
