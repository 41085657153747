import styled from 'styled-components';

const Styledcontainer = styled.article`
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #222222;
  border-radius: 32px;
  height: 8px;
`;

const StyledProgress = styled.div`
  width: ${(props) => (props.value * 100) / props.max}%;
  background-color: ${({ theme }) => theme.mainColor};
  height: 100%;
  border-radius: 32px;
`;
const ProgressBar = ({ value, max }) => {
  return (
    <Styledcontainer>
      <StyledProgress value={value} max={max} />
    </Styledcontainer>
  );
};

export default ProgressBar;
