import { useCallback, useRef } from 'react';
import { useGtagEvent } from '../GoogleAnalytics/GoogleAnalytics';

const withFunnelController = (Component) => ({ ...props }) => {
  const gtagEvent = useGtagEvent();
  const funnelState = useRef([]);

  const sendEvent = useCallback((event, parameters = {}) => {
    if (props.funnelStates.indexOf(event) > -1) {
      if (funnelState.current.indexOf(event) === -1) {
        gtagEvent(event, parameters);
        funnelState.current = [...funnelState.current, event];
      }
    } else {
      gtagEvent(event, parameters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Component {...props} sendEvent={sendEvent} />;
};

export default withFunnelController;
