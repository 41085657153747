import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
body{
    font-family: ${({ theme }) => theme.fontFamily};
    h1{
        font-size: 3vh
    }
}

`;
export default GlobalStyle;
