import { useEffect } from 'react';
import styled from 'styled-components';

const StyledEnding = styled.article`
  display: flex;
  flex-direction: column;
  flex: 1;
  img:nth-child(3) {
    width: 20vh;
  }
`;
const StyledContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin: 0 auto;
`;
const StyledButton = styled.button(({ theme }) => {
  return {
    ...theme.mainButton,
    backgroundColor: theme.mainColor,
  };
});

export const Ending = ({ content, text, sendEvent, state, onEnd }) => {
  useEffect(() => {
    sendEvent(`state_${state}`);
  }, [sendEvent, state]);

  return (
    <StyledEnding>
      <StyledContent
        dangerouslySetInnerHTML={{ __html: content }}
      ></StyledContent>
      {state === 'endingKo' ? (
        <StyledButton onClick={() => onEnd()}>{text}</StyledButton>
      ) : null}
    </StyledEnding>
  );
};
