import { Helmet } from 'react-helmet';

function Head({ data }) {
  return (
    <Helmet>
      <title>{data.metadata.title}</title>
      <meta name='description' content={data.metadata.description} />
    </Helmet>
  );
}

export default Head;
