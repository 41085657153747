import { useEffect } from 'react';
import styled from 'styled-components';

const StyledEnding = styled.article`
  display: flex;
  flex-direction: column;
  flex: 1;
  img:nth-child(3) {
    width: 20vh;
  }
`;
const StyledContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin: 0 auto;
`;

const StyledButtonContainer = styled.div(({ theme }) => {
  if (theme.mode === 'landscape') {
    return {
      justifyContent: 'center',
      flexDirection: 'row-reverse',
      display: 'flex',
    };
  } else {
    return {
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex',
    };
  }
});

const StyledSendButton = styled.button(({ theme }) => {
  return {
    ...theme.mainButton,
    backgroundColor: theme.mainColor,
    margin: theme.mode === 'portrait' ? '0px 0px 16px 0px' : '0px 0px 0px 16px',
  };
});

const StyledReviewButton = styled.button(({ theme }) => {
  return {
    ...theme.mainButton,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.mainColor,
    backgroundColor: '#fff',
    color: theme.mainColor,
    margin: '0',
  };
});

export const PreEnding = ({ content, sendEvent, onEnd, onBack }) => {
  useEffect(() => {
    sendEvent('state_preEnding');
  }, [sendEvent]);
  return (
    <StyledEnding>
      <StyledContent
        dangerouslySetInnerHTML={{ __html: content }}
      ></StyledContent>
      <StyledButtonContainer>
        <StyledSendButton onClick={onEnd}>Enviar</StyledSendButton>
        <StyledReviewButton onClick={onBack}>Revisar</StyledReviewButton>
      </StyledButtonContainer>
    </StyledEnding>
  );
};
