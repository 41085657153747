import Question from './Question';
import { useState } from 'react';
import ProgressBar from '../../ui/ProgressBar';
import styled from 'styled-components';
import { useEffect } from 'react';

const StyledArticle = styled.article`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledQuestionWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
`;

const StyledProgress = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledProgressContainer = styled.article`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  flex: 1;
  max-width: 600px;
`;

const StyledText = styled.p`
  margin: 0;
  font-size: 12px;
  margin-top: 8px;
`;

const StyledBackButton = styled.img`
  content: url('${({ theme }) => theme.arrow}');
  cursor: pointer;
`;
const StyledNextButton = styled.img`
  content: url('${({ theme }) => theme.arrow}');
  transform: rotate(180deg);
  cursor: pointer;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
`;

const StyledProgressWrapper = styled.div`
  flex: 6;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Questions = ({
  questions,
  onEnd,
  sendEvent,
  onAnswers,
  savedAnswers,
  initial,
}) => {
  const [progressValue, setProgressValue] = useState(0);
  const [idx, setIdx] = useState(initial || 0);
  const [answers, setAnswers] = useState(
    savedAnswers || questions.map(() => ({ values: [] }))
  );

  const getSendState = () => {
    if (idx < questions.length - 1) {
      return 'hidden';
    } else {
      return answers[answers.length - 1].values.length ? 'enabled' : 'disabled';
    }
  };

  const goNext = () => {
    if (idx < questions.length - 1) {
      setIdx(idx + 1);
      setProgressValue(progressValue + idx);
      sendEvent('go_nextQuestion');
    } else {
      if (surveyOk()) {
        onEnd(answers);
        sendEvent('go_sendAnswers', answers);
      }
    }
  };

  const goBack = () => {
    idx > 0 && setIdx(idx - 1);
    sendEvent('go_previusQuestion');
  };

  const surveyOk = () => {
    return answers.reduce((acc, item) => {
      return acc && item && (!Array.isArray(item) || item.length);
    }, true);
  };

  const handleAnswered = (value, next = true) => {
    const _answers = answers.map((item, i) => (i === idx ? value : item));
    setAnswers(_answers);
    onAnswers(_answers);
    next &&
      setTimeout(() => {
        goNext();
      }, 700);
  };
  const sendState = getSendState();

  const showNext = () => {
    if (idx < questions.length - 1) {
      if (questions[idx].optional) {
        return true;
      } else {
        return Object.keys(answers[idx]).length > 0;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    sendEvent('state_questions');
  }, [sendEvent]);

  return (
    <StyledArticle>
      <StyledQuestionWrapper>
        <Question
          answer={answers[idx]}
          question={questions[idx]}
          onAnswered={handleAnswered}
          sendEvent={sendEvent}
        />
      </StyledQuestionWrapper>
      <Container>
        <StyledProgressContainer>
          <StyledButtonWrapper>
            {idx !== 0 && (
              <StyledBackButton onClick={goBack} alt='go back arrow' />
            )}
          </StyledButtonWrapper>
          <StyledProgressWrapper>
            <ProgressBar
              value={sendState === 'enabled' ? questions.length : idx}
              max={questions.length}
            />
            <StyledProgress>
              <StyledText>
                {idx + 1} de {questions.length} respondidas
              </StyledText>
            </StyledProgress>
          </StyledProgressWrapper>
          <StyledButtonWrapper>
            {showNext() ? (
              <StyledNextButton onClick={goNext} alt='go next button' />
            ) : null}
          </StyledButtonWrapper>
        </StyledProgressContainer>
      </Container>
    </StyledArticle>
  );
};

export default Questions;
