import styled from 'styled-components';

import { useState } from 'react';

const StyledLabel = styled.label`
  position: relative;
  display: flex;
  align-items: baseline;
`;

const StyledTextWrapper = styled.div`
  padding-left: 8px;
`;

const StyledCircle = styled.div(({ selected, theme }) => {
  return selected
    ? { ...theme.selectTick.base, ...theme.selectTick.active }
    : theme.selectTick.base;
});

const Answer = ({ item, onResponse, selected, answer }) => {
  const [inputValue, setInputValue] = useState(answer.comments || '');
  const handleClick = () => {
    !item.comments && onResponse({ value: item.value });
  };
  const handleComment = () => {
    onResponse({ value: item.value, comments: inputValue });
  };

  return (
    <>
      <StyledLabel onClick={handleClick}>
        <StyledCircle selected={selected}></StyledCircle>
        <StyledTextWrapper>{item.text}</StyledTextWrapper>
      </StyledLabel>
      {item.comments && (
        <>
          <input
            type='text'
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <button onClick={handleComment}>Respuesta definitiva</button>
        </>
      )}
    </>
  );
};

export default Answer;
