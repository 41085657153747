import styled from 'styled-components';
import useViewMode from '../../../../hooks/useViewMode';

const StyledList = styled.li`
  list-style-type: none;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledItem = styled.label``;

const StyledRange = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
`;

const StyledUl = styled.ul`
  padding: 0px;
  display: flex;
  justify-content: space-evenly;
  flex: ${({ theme }) => (theme.mode === 'landscape' ? '1' : null)};
`;

const StyledTitle = styled.h2`
  font-size: 20px;
`;
const StyledAnswerRange = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCircle = styled.div(({ selected, theme }) => {
  return selected
    ? { ...theme.selectTick.base, ...theme.selectTick.active }
    : theme.selectTick.base;
});

const Evaluate = ({ question, onAnswered, answer }) => {
  const mode = useViewMode();
  const renderItems = () => {
    return (
      <StyledUl>
        {[...Array(question.max - question.min + 1).keys()]
          .map((i) => i + question.min)
          .map((elm, idx) => {
            return (
              <StyledList
                onClick={() => {
                  onAnswered({ values: [{ value: elm }] });
                }}
                key={idx}
              >
                <StyledCircle
                  selected={answer.values.find((it) => it.value === elm)}
                />
                <StyledItem>{elm}</StyledItem>
              </StyledList>
            );
          })}
      </StyledUl>
    );
  };

  return (
    <>
      <StyledTitle>{question.title}</StyledTitle>
      {mode === 'landscape' ? (
        <StyledAnswerRange>
          <StyledRange>
            <label>{question.minLabel}</label>
          </StyledRange>
          {renderItems()}
          <StyledRange>
            <label>{question.maxLabel}</label>
          </StyledRange>
        </StyledAnswerRange>
      ) : (
        <>
          {renderItems()}
          <StyledRange>
            <label>{question.minLabel}</label>

            <label>{question.maxLabel}</label>
          </StyledRange>
        </>
      )}
    </>
  );
};

export default Evaluate;
