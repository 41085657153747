import Select from './Types/Select';
import Evaluate from './Types/Evaluate';
import styled from 'styled-components';
import { useState } from 'react';

const StyledContainer = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 600px;
`;

const Question = ({ question, onAnswered, answer, sendEvent }) => {
  const [iniDate] = useState(new Date());

  const handleAnswered = (obj, next) => {
    const passedTime = new Date() - iniDate;
    const _obj = { ...obj, answerDuration: passedTime };
    onAnswered(_obj, next);
    next && sendEvent('go_answered');
  };

  const getType = (question) => {
    switch (question.type) {
      case 'selectOne':
      case 'selectMany':
        return (
          <Select
            question={question}
            answer={answer}
            onAnswered={handleAnswered}
          />
        );
      case 'evaluate':
        return (
          <Evaluate
            question={question}
            answer={answer}
            onAnswered={handleAnswered}
          />
        );
      default:
        break;
    }
  };
  return <StyledContainer>{getType(question)}</StyledContainer>;
};

export default Question;
