import { useEffect, useState, useRef } from 'react';

const useViewMode = () => {
  const querys = useRef();
  querys.current = [1440, 1024, 768, 414, 360];

  const [mode, setMode] = useState('portrait');

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setMode(window.innerWidth < 768 ? 'portrait' : 'landscape');
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return mode;
};

export default useViewMode;
