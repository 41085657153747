import Answer from '../Answer';
import styled from 'styled-components';

const StyledList = styled.li`
  list-style-type: none;
  margin-top: 8px;
`;

const StyledUl = styled.ul`
  overflow-y: auto;
  flex: 1;
  margin-top: 0;
  padding-left: 8px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    border: 1px solid black;
    border-radius: 10px;
  }

  &::-webkit-scrollbar:vertical {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.mainColor};
    border-left: 1px solid #222222;
    border-right: 1px solid #222222;
    box-sizing: border-box;
    border-top: none;
    border-bottom: none;
  }
`;
const StyledTitle = styled.h2`
  font-size: 20px;
`;

const Select = ({ question, onAnswered, answer }) => {
  const handleClick = (obj) => {
    if (question.type === 'selectMany') {
      if (answer.values.find((it) => obj.value === it.value)) {
        onAnswered(
          {
            values: answer.values.filter((it) => it.value !== obj.value),
          },
          false
        );
      } else {
        onAnswered({ values: [...answer.values, obj] }, false);
      }
    } else {
      onAnswered({ values: [obj] });
    }
  };

  return (
    <>
      <StyledTitle>{question.title}</StyledTitle>

      <StyledUl>
        {question.answers.map((elm, idx) => {
          //const myAnswer =
          //  safeAnswer.find((item) => item.value === elm.value) || {};
          return (
            <StyledList key={idx}>
              <Answer
                item={elm}
                selected={answer.values.find((it) => elm.value === it.value)}
                onResponse={handleClick}
                answer={answer}
              />
            </StyledList>
          );
        })}
      </StyledUl>
      {question.type === 'selectMany' && answer.values.length > 0 ? (
        <button onClick={() => onAnswered(answer)}>Aceptar</button>
      ) : null}
    </>
  );
};

export default Select;
